<template>
  <div class="fancy-text-block-twentyThree lg-container pt-200 pt-30 md-mt-90" id="feature">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 order-lg-last" data-aos="fade-left">
          <div class="text-wrapper ps-xl-5">
            <div class="cl-text">
              Tailered to <span>your business</span> size and needs
            </div>
            <div class="title-style-twelve">
              <h2>Platform that shows you the way..</h2>
              <p class="mt-35 mb-60 md-mb-40">
                Empowers transformers of businesses to take control of their
                digital journey and unlock the power of technology to drive
                growth and success, one click at a time.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6" data-aos="fade-right">
          <div class="img-container position-relative md-mt-60">
            <img src="@/assets/images/banner/platform-banner.png" alt="" class="main-screen" />
            <img src="@/assets/images/banner/platform-subbanner.png" alt="" class="screen-one" />
          </div>
        </div>
      </div>
    </div>

    <div class="mt-90 md-mt-50">
      <div class="container">
        <div class="row justify-content-between">
          <div v-for="item in textBlockData" :key="item.id" class="col-xl-3 col-md-4" data-aos="fade-up"
            :data-aos-delay="item.delay">
            <div class="block-style-twentyEight text-center mt-40">
              <div class="icon d-flex justify-content-center align-items-end">
                <img :src="item.icon" alt="" />
              </div>
              <h4>{{ item.title }}</h4>
              <p>{{ item.subtitle }}</p>
            </div>
            <!-- /.block-style-twentyEight -->
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>

export default {
  data() {
    return {
      textBlockData: [
        {
          id: 1,
          icon: require(`@/assets/images/icon/105-c.svg`),
          title: "Dynamic",
          subtitle:
            "Tailered to your ever-changing unique organization needs, demands and ambitions",
        },
        {
          id: 2,
          icon: require(`@/assets/images/icon/109-c.svg`),
          title: "Intuitive",
          subtitle:
            "A few simple questions will get your organization started with your roadmap to digital transformation",
          delay: "100",
        },
        {
          id: 3,
          icon: require(`@/assets/images/icon/108-c.svg`),
          title: "Intelligent",
          subtitle:
            "Help you identify areas for improvement and leave the preference to your pace",
          delay: "200",
        },
        {
          id: 4,
          icon: require(`@/assets/images/icon/110-c.svg`),
          title: "Progressive",
          subtitle:
            "Elevating your business to the next level of digital maturity, from where you stand",
          delay: "200",
        },
      ],
    };
  },
};
</script>
