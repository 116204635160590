<template>
  <footer
    :class="`theme-footer-seven ${
      dark_bg ? 'dark-bg pt-120 md-pt-100' : 'mt-120 md-mt-100'
    }`"
  >
    <div class="lg-container">
      <div class="container">
        <div class="row">
          <div
            class="col-xl-3 col-lg-2 mb-40"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <h5 class="title">About us</h5>

            <div>
              Distruptive Platform that assists digital transformers of
              businesses, navigate the complex and ever-changing landscape of
              digital technology..
            </div>
          </div>
          <div
            class="col-lg-1 col-md-6 mb-40"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="150"
          >
            <!-- <h5 class="title">Legal</h5>
            <ul class="footer-list">
              <li><a href="#">Terms of use</a></li>
              <li><a href="#">Terms & conditions</a></li>
              <li><a href="#">Privacy policy</a></li>
              <li><a href="#">Cookie policy</a></li>
            </ul> -->
          </div>
          <div
            class="col-lg-4 col-md-6 mb-40"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="100"
          >
            <h5 class="title">Links</h5>
            <ul class="footer-list">
              <li><a href="/">Home</a></li>
              <li><router-link to="/transformer-hub">Transformer's Hub</router-link></li>
              <li><router-link to="/contact-us">Contact us</router-link></li>
              <!-- <li>
                <a
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#contactModal"
                  class="demo-button"
                >
                  <span>Contact us</span>
                </a>
              </li> -->
              <li>
                <router-link to="/terms-and-conditions">Legalese</router-link>
              </li>
            </ul>
          </div>

          <div
            class="col-xl-4 col-lg-5 mb-40"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="200"
          >
            <div class="newsletter">
              <h5 class="title">Newsletter</h5>
              <p>Join transformers getting our emails</p>
              <form @submit.prevent="onSubmit">
                <input type="email" placeholder="Enter your email" required="required" v-model="email" />
                <button :class="dark_btn ? 'dark-btn' : ''">Subscribe</button>
              </form>
              <div v-if="result === null" class="info">
                We only send interesting and relevant emails.
              </div>
              <div v-else class="result-message" :class="{'warning': hasError}">
                {{result }}
              </div>
            </div>
            <!-- /.newsletter -->
          </div>
        </div>
      </div>

      <div class="container">
        <div class="bottom-footer">
          <div class="row">
            <div class="col-lg-4 order-lg-1 mb-20">
              <ul
                class="
                  d-flex
                  justify-content-center justify-content-lg-start
                  footer-nav
                "
              >
                <li>
                  <router-link to="/terms-and-conditions"
                    >Terms & Conditions</router-link
                  >
                </li>
                <!-- <li><a href="#">Contact Us</a></li> -->
              </ul>
            </div>
            <div class="col-lg-4 order-lg-3 mb-20">
              <ul
                class="
                  d-flex
                  justify-content-center justify-content-lg-end
                  social-icon
                "
              >
                <!-- <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li> -->
                <li>
                  <a
                    href="https://linkedin.com/company/overmind-digital"
                    target="_blank"
                    ><i class="fa fa-linkedin" aria-hidden="true"></i
                  ></a>
                </li>
              </ul>
            </div>
            <div class="col-lg-4 order-lg-2 mb-20">
              <p class="copyright text-center">
                Copyright @ 2023 Overmind Digital, Inc.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.lg-container -->
  </footer>
</template>

<script>
import axios from 'axios'
export default {
  props: {
    dark_btn: Boolean,
    dark_bg: Boolean,
  },
  data(){
    return {
      email: '',
      hasError: false,
      result: null
    }
  },
  methods: {
    async onSubmit() {
      await axios
        .post("https://api.overmind.digital/api/Lead/Newsletter", {
          email: this.email,
        })
        .then(() => {
          this.email = '';
          this.result = 'Thank you, we will not disappoint..';
        })
        .catch(() => {
          this.result = 'Oops. somthing went wrong, please try again later';
          this.hasError = true;
        });
    },
  },
};
</script>

<style scoped>
.result-message {
  color: #6102ea;
  font-weight: 500;
}
.warning {
  color: red;
}
</style>