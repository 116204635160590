<template>
  <div class="service-details-one pt-100 md-pt-50">
    <div class="container">

      <div class="row">
        <div class="col-xl-9 col-lg-8 order-lg-last">
          <div class="details-wrapper ps-xl-5">
            <p class="font-gordita pb-30 md-pb-40">
              {{ detail.preintro }}
            </p>
            <img :src="detail.headerImage" alt="" class="img-meta w-100">
            <p class="pt-40 pb-40" v-html="detail.driver"></p>
            <div class="row mb-50">
              <div class="col-md-6">
                <h4 class="font-gordita pt-40">Our Goal</h4>
                <ul class="list-item-one">
                  <li>Various analysis options.</li>
                  <li>Page Load (time, number of requests).</li>
                  <li>Big data analysis.</li>
                </ul>
              </div>
              <div class="col-md-6">
                <h4 class="font-gordita pt-40">The Challange</h4>
                <p>Deski Web offers a complete lineup of features from any major Maecena quis interdum, orci at euis
                  dapibus, mass ante pharetra tellus massa ante pharetra tellus.</p>
              </div>
            </div>

            <div class="mb-40" v-html="detail.intro" />

            <div class="mb-70">
              <ul class="procedure">
                <li v-for="(step, index) in detail.steps" :key="index">
                  <h2>
                    Step {{ index + 1 }}: <span v-html="step.title" />
                  </h2>
                  <div v-html="step.description"></div>
                </li>
              </ul>
            </div>

            <div class="mb-40" v-html="detail.precon" />

            <div class="mb-40">
              <div v-if="detail.files && detail.files.length > 0">
                <h4 class="mb-10">Documents</h4>
                <ul>
                  <li class="file" v-for="(file, index) in detail.files" :key="index + 1">
                    <span><img :src="file.icon" :alt="file.title"/></span>
                    <span><a :href="file.path">{{ file.title }}</a></span>
                  </li>
                </ul>
              </div>
            </div>

            <div class="mb-40" v-html="detail.conc" />


          </div> <!-- /.details-wrapper -->


        </div>
        <div class="border-end col-xl-3 col-lg-4 col-md-6 order-lg-first">
          <div class="sidebar md-mt-50">
            <div>
              <nav class="main-side-nav d-none d-xl-block">
                <a href="#introduction" class="nav-link">Introduction</a>
                <a href="#drivers" class="nav-link">Change drivers</a>
                <a href="#body" class="nav-link">The gold</a>
                <a href="#steps" class="nav-link">Steps to carry</a>
                <a href="#documents" class="nav-link">Documents to use</a>
                <a href="#thewayforward" class="nav-link">Lead on</a>
              </nav>
            </div>
            <div class="share-option mt-30">
              <h4 class="font-gordita">Share it.. </h4>
              <ul class="d-flex social-icon align-items-center justify-content-center">
                <li><a :href="facebookUrl"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                <li><a :href="twitterUrl"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                <li><a :href="linkedinUrl"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
              </ul>
            </div>
            <section class="reaction-wrapper mt-30">
              <div v-if="!isRated">
                <h4>Rate it..</h4>
                <div class="ratings d-flex align-items-center justify-content-center">
                  <button @click="rateArticle(detail.artId, 3)">
                    <img src="@/assets/images/icon/happy.svg" alt="Happy happy happy" />
                  </button>
                  <button @click="rateArticle(detail.artId, 2)">
                    <img src="@/assets/images/icon/surprised.svg" alt="Should be better" />
                  </button>
                  <button @click="rateArticle(detail.artId, 1)">
                    <img src="@/assets/images/icon/sad.svg" alt="Not what Im here for" />
                  </button>
                </div>
              </div>
              <div v-else class="result-message">
                Thank you for your feedback.
              </div>
            </section>
          </div> <!-- /.sidebar -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["detail"],
  computed: {
    facebookUrl() {
      return (
        "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href
      );
    },
    twitterUrl() {
      return (
        "https://twitter.com/share?url=" +
        window.location.href +
        "&text=" +
        this.detail.title
      );
    },
    linkedinUrl() {
      return (
        "https://www.linkedin.com/shareArticle?mini=true&url=" +
        window.location.href +
        "&title=" +
        this.detail.title +
        "&summary=" +
        this.detail.title +
        "&source=" +
        window.location.href
      );
    },
  },
  data() {
    return {
      isRated: false,
    };
  },
  methods: {
    async rateArticle(id, rateLvl) {
      await axios
        .post("https://api.overmind.digital/api/Article/Rating", {
          articleId: id,
          rateLevel: rateLvl,
          rater: "",
        })
        .then(() => {
          this.result = "Messages sent successfully, we will in touch soon.";
          this.isRated = true;
        })
        .catch(() => {
          this.result = "Oops. somthing went wrong, please try again later";
          this.hasError = true;
        });
    },
    track() {
      this.$gtag.pageview({
        page_path: window.location.href,
      });
    },
  },
};
</script>

<style scoped>
.result-message {
  color: #6102ea;
  font-weight: 500;
}

.file {
  display: flex;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-align: center;
  vertical-align: middle;
}

.file img {
  width: 32px;
  margin-right: 10px;
}

.procedure li {
  margin-bottom: 35px;
}

.procedure li h2 {
  font-size: 1.6rem;
  line-height: 3rem;
}

.ratings button {
  margin-right: 15px;
}

.ratings img {
  width: 35px;
  height: 35px;
}

.sidebar {
  position: sticky;
  overflow-x: visible;
  top: 0;
}

.sidebar h4 {
  font-size: 12px;
}
</style>