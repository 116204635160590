<template>
  <div class="main-page-wrapper font-gordita overflow-hidden">
    <contact-banner />
    <contact-form />
  </div>
</template>

<script>
import ContactBanner from "@/components/contact/ContactBanner.vue";
import ContactForm from "@/components/contact/ContactForm.vue";

export default {
  components: {  ContactBanner, ContactForm, },
  methods: {
    track() {
      this.$gtag.pageview({
        page_path: "/contact-us",
      });
    },
  },
};
</script>