<template>
  <div>
    <div
      :class="`theme-main-menu sticky-menu theme-menu-five ${isSticky ? 'fixed' : ''
      }`"
    >
      <div class="d-flex align-items-center justify-content-center">
        <div v-if="dark" class="logo">
          <router-link to="/">
            <img src="@/assets/images/logo/overmind_logo.png" alt="" />
          </router-link>
        </div>
        <div v-else class="logo">
          <router-link to="/">
            <img src="@/assets/images/logo/overmind_logo.png" alt="" />
          </router-link>
        </div>

        <nav id="mega-menu-holder" class="navbar navbar-expand-lg">
          <div class="nav-container">
            <button
              class="navbar-toggler"
              data-bs-toggle="collapse"
              data-bs-target="#collapsibleNavbar"
              :class="menuActive ? 'navbar-toggler open' : 'navbar-toggler'"
              @click="menuActive = !menuActive"
            >
              <span></span>
            </button>
            <div
              :class="
                menuActive
                  ? 'navbar-collapse collapse ss'
                  : 'navbar-collapse collapse'
              "
              id="collapsibleNavbar"
            >
              <div class="d-lg-flex justify-content-between align-items-center">
                <ul
                  class="navbar-nav main-side-nav font-gordita"
                  id="one-page-nav"
                >
                  <li v-if="doc_signature" class="nav-item">
                    <router-link to="/" class="nav-link"
                      ><span @click.self="menuActive = !menuActive"
                        >Home</span
                      ></router-link
                    >
                  </li>
                  <li v-if="doc_signature" class="nav-item">
                    <router-link to="/transformer-hub" class="nav-link"
                      ><span @click.self="menuActive = !menuActive"
                        >Transformer's Hub</span
                      ></router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>


        <div class="right-widget">
          <a
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#contactModal"
            class="demo-button"
          >
            <span>Get advised</span>
            <img
              v-if="!dark"
              src="@/assets/images/icon/askus.png"
              alt=""
            />
            <img
              v-if="dark"
              src="@/assets/images/icon/askus.png"
              alt=""
            />
          </a>
        </div>
      </div>
    </div>

    <!-- ContactModal start -->
    <ContactModal :dark="dark" />
    <!-- ContactModal end -->
  </div>
</template>

<script>
import ContactModal from "@/components/contact/ContactModal.vue";

export default {
  components: {
    ContactModal,
  },
  props: {
    dark: Boolean,
    doc_signature: Boolean,
  },
  data() {
    return {
      isSticky: false,
      menuActive: false,
    };
  },
  methods: {
    handleSticky() {
      if (window.scrollY > 80) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleSticky);
  },
};
</script>
