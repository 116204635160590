<template>
  <div class="faq-section-five lg-container pt-200 pt-25 md-mt-80" id="faq">
    <div class="container">
      <div class="row">
        <div class="col-xl-5 col-lg-6">
          <div class="title-style-twelve">
            <div class="upper-title">FAQ</div>
            <h2>Got questions?</h2>
            <p class="pt-25 pb-40 md-pb-30">
              Find your answers here. if you don’t, please contact
              us.
            </p>
            <router-link to="/contact-us" class="theme-btn-eight"
              >Contact us</router-link
            >
          </div>
        </div>
        <div class="col-lg-6 ms-auto">
          <div id="accordionExample" class="accordion-style-five md-mt-60">
            <div v-for="item in faqData" :key="item.id" class="accordion-item">
              <div class="accordion-header" :id="item.headingNum">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#' + item.collapseNum"
                    :aria-expanded="item.expanded ? 'true' : 'false'"
                    :aria-controls="item.collapseNum"
                  >
                    {{ item.btnText }}
                  </button>
                </h5>
              </div>
              <div
                :id="item.collapseNum"
                :class="item.collapseClass"
                :aria-labelledby="item.headingNum"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p>
                    {{ item.desc }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FaqArea",
  data() {
    return {
      faqData: [
        {
          id: 1,
          headingNum: "headingOne",
          collapseNum: "collapseOne",
          btnText: "What the difference between free and paid account?",
          desc: `The free account allows you access to all content without the ability to export editable formats. The paid version do.`,
          collapseClass: "accordion-collapse collapse",
        },
        {
          id: 2,
          headingNum: "headingTwo",
          collapseNum: "collapseTwo",
          btnText: "What is the payment mode?",
          desc: `We offer multiple subscription levels that fits all users needs.`,
          collapseClass: "accordion-collapse collapse",
        },
        {
          id: 3,
          headingNum: "headingThree",
          collapseNum: "collapseThree",
          btnText: "What’s included in my free trial plan?",
          desc: `Access to all content with ability to export 3 documents in the trial period.`,
          collapseClass: "accordion-collapse collapse",
        },
        {
          id: 4,
          headingNum: "headingFour",
          collapseNum: "collapseFour",
          btnText: "How can I get notified with live product?",
          desc: `We advise you to subscribe to our news letter, alot of useful content will be shared.`,
          collapseClass: "accordion-collapse collapse",
        },
      ],
    };
  },
};
</script>
