<template>
  <div class="main-page-wrapper">
    <div class="error-page d-flex align-items-center">
      <div class="row ">
        <div class="col-2"></div>
        <div class="text-wrapper col-md-6 d-flex justify-content-center">
          <div class=" mb-30">
            <h1 class="font-recoleta">What brought you here ..?</h1>
            <p class="font-rubik px-2">
              The tressure you are trying to dig is not available at this address,
              may be you should start the begining..
            </p>
            <router-link to="/" class="
              back-home
              font-rubik
              d-flex
              align-items-center
              justify-content-center
            ">
              <span>Find your way home</span>
              <img src="@/assets/images/icon/53.svg" alt="" />
            </router-link>
          </div>
        </div>
        <div class="col-md-4 mt-60">
          <img src="@/assets/images/media/wth-404.png" alt="" />
        </div>
      </div>

    </div>
  </div>
</template>


<script>
export default {
  methods: {
    track() {
      this.$gtag.pageview({
        page_path: "/were-lost",
      });
    },
  },
};
</script>

