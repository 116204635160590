import { createRouter, createWebHistory } from 'vue-router'
import { trackRouter } from 'vue-gtag-next';
import NotFoundPage from '../pages/NotFound.vue';
import TermsCondition from '../pages/TheDisclaimer.vue';
import TheHome from '../pages/TheHome.vue'
import ContactUs from '../pages/ContactUs.vue'
import TransformerHub from '../pages/TransformerHub.vue'
import TheArticle from '../pages/TheArticle.vue'


const routes = [
    {
        path: '/',
        component: TheHome,
        meta: {
            title: 'Your Digital Transaformation Assistant',
            description: 'disruptive platform to assist digital transformers for all types and sizes of businesses, get all the help they need in they exciting journey.',
            keywords: 'Digital transformation assistant, Business transformation solutions, Digital strategy consulting, Change management services, Innovation consulting, Digital solutions provider, Automation tools and services, Data analytics consulting, Cloud computing solutions, Artificial intelligence consulting, Machine learning solutions, Customer experience optimization, Agile methodologies consulting, Digital maturity assessment, digital maturity roadmap, Industry 4.0 consulting'
        },
    },
    {
        path: '/transformer-hub',
        component: TransformerHub,
        meta: {
            title: 'Transformer Hub',
            description: 'Transform the way you do business with our witty and informative digital transformation blog! Join the revolution! 💻💥',
            keywords: 'Digital transformation trends, Business transformation insights, Digital strategy best practices, Change management tips, Innovation thought leadership, Digital solutions case studies, Automation news and updates, Data analytics industry analysis, Cloud computing trends and insights, Artificial intelligence advancements, Machine learning use cases, Customer experience strategies, Agile methodologies best practices, Digital maturity journey, Industry 4.0 developments'
        }
    },
    {
        path: '/article/:seo',
        component: TheArticle,
        meta: {
            title: '',
            description: '',
            keywords: ''            
        },
        props: true
    },  
    {
        path: '/contact-us',
        component: ContactUs,
        meta: {
            title: 'Contact us',
            description: "Got questions? We've got answers! Drop us a line and our friendly support team will get back to you faster than a superhero! 🦸‍♀️",
            keywords: 'Contact Overmind.digital, Digital transformation consultation, Business transformation inquiry, Digital strategy assessment, Change management inquiry, Innovation consultation request, Digital solutions inquiry, Automation services request, Data analytics consultation request, Cloud computing solutions inquiry, Artificial intelligence consultation request, Machine learning solutions inquiry, Customer experience optimization inquiry, Agile methodologies consultation request'            
        },
    },    
    {
        path: '/terms-and-conditions',
        component: TermsCondition,
        meta: {
            title: 'Terms & Conditions',
            description: 'our terms, providing a clear outline of the rules and regulations governing the use of our website, ensuring a safe and enjoyable experience for all users',
            keywords: 'Terms and conditions of use, Privacy policy, overmind.digital termd and condition'
        },
    },
    {
        path: '/were-lost',
        component: NotFoundPage,
        meta: {
            title: 'We are lost!',
            description: "Don't panic! You've stumbled upon our lost & found, take a deep breath and let's get back on track together. 😉",
            keywords: 'find your way home'
        },
    },
    {
        path: '/:pathMatch(.*)*',
        component: NotFoundPage,
        meta: {
            title: 'Lost & Found Page',
            description: "Don't panic! You've stumbled upon our lost & found, take a deep breath and let's get back on track together. 😉",
            keywords: 'find your way home'
        },
    },
   
]

const router = createRouter({
    mode: 'history',
    history: createWebHistory(),
    routes
})

trackRouter(router);

router.beforeEach((to, from, next) => {
    document.title = to.meta.title + '| Overmind Digital' ;
    document.querySelector("meta[name='description']").setAttribute('content', to.meta.description);
    document.querySelector("meta[name='keywords']").setAttribute('content', to.meta.keywords);
    next();
    window.scrollTo(0, 0)
});

export default router
