import { createApp } from "vue";
import VueEasyLightbox from "vue-easy-lightbox";
import store from './store';
import router from "./routes";
import "bootstrap";
import "./index.css";
import VueGtag from 'vue-gtag-next';
import App from "./App.vue";


let app = createApp(App);
app.use(store);
app.use(router);
app.use(VueEasyLightbox);
app.use(VueGtag, {
    property: {
      id: "G-8JG0F2ED4N"
    }
  });
app.mount("#app");
