<template>
  <div class="feature-blog-one blog-page-bg">
    <div class="shapes shape-one"></div>
    <div class="shapes shape-two"></div>
    <div class="shapes shape-three"></div>
    <div class="container">
      <div class="row">
        <div
          v-for="blog in articles"
          :key="blog.id"
          class="col-lg-4 col-md-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          :data-aos-delay="300"
        >
          <div class="post-meta">
            <img :src="blog.postImage" width="370" height="270" alt="" class="image-meta" />
            <div class="tag">{{ blog.tag }}</div>
            <h3>
              <router-link :to="`/article/${blog.seoAddress}`" class="title">{{
                blog.title
              }}</router-link>
            </h3>
            <router-link
              :to="`/article/${blog.seoAddress}`"
              class="
                read-more
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <span>Read More</span>
              <i class="flaticon-right-arrow"></i>
            </router-link>
          </div>
          <!-- /.post-meta -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      articles: null,
    };
  },
  created() {
    axios
      .get("https://api.overmind.digital/api/Article/Header")
      .then((response) => {
        this.articles = response.data;
      })
      .catch(() => {
        // handle error
      })
      .finally(() => {
        // always executed
      });
  },
};
</script>