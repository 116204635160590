<template>
  <div class="contact-us-light pb-100 md-pb-30">
    <div class="bubble-one"></div>
    <div class="bubble-two"></div>
    <div class="bubble-three"></div>
    <div class="bubble-four"></div>
    <div class="container">
      <div class="form-style-light">
        <form
          @submit.prevent="onSubmit"
          id="contact-form"
          data-bs-toggle="validator"
        >
          <div class="row controls">
            <div class="col-md-6">
              <div class="input-group-meta form-group mb-35">
                <label>First Name</label>
                <input
                  type="text"
                  name="Fname"
                  v-model="firstName"
                  required="required"
                  data-error="Name is required."
                />
                <span class="placeholder_icon valid-sign">
                  <img src="@/assets/images/icon/18.svg" alt="" />
                </span>
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-group-meta form-group mb-35">
                <label>Last Name</label>
                <input
                  type="text"
                  name="Lname"
                  v-model="lastName"
                  required="required"
                  data-error="Name is required."
                />
                <span class="placeholder_icon valid-sign">
                  <img src="@/assets/images/icon/18.svg" alt="" />
                </span>
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-12">
              <div class="input-group-meta form-group mb-35">
                <label>Your Email</label>
                <input
                  type="email"
                  name="email"
                  v-model="email"
                  required="required"
                  data-error="Valid email is required."
                />
                <span class="placeholder_icon valid-sign">
                  <img src="@/assets/images/icon/18.svg" alt="" />
                </span>
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-12">
              <div class="input-group-meta form-group lg mb-35">
                <label>Your Message</label>
                <textarea
                  name="message"
                  v-model="message"
                  required="required"
                  data-error="Please,leave us a message."
                ></textarea>
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-12 d-flex">
              <div>
                <button class="theme-btn-one btn-lg">Send Message</button>
              </div>
              <div v-cloak class="messages" :class="{ 'warning' : hasError } ">
                {{ result }}
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- /.form-style-light -->
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      message: '',
      result: null,
      hasError: false,
    };
  },
  methods: {
    async onSubmit() {
      await axios
        .post("https://api.overmind.digital/api/Lead/Contact", {
          name: this.firstName + ' ' + this.lastName,
          email: this.email,
          message: this.message
        })
        .then(() => {
          this.firstName = '';
          this.lastName = '';
          this.email = '';
          this.message = '';
          this.result = 'Messages sent successfully, we will in touch soon.';
        })
        .catch(() => {
          this.result = 'Oops. somthing went wrong, please try again later';
          this.hasError = true;
        });
    },
  },
};
</script>

<style scoped>

.warning {
  color: red;
}

</style>