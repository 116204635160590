<template>
  <div class="main-page-wrapper font-gordita overflow-hidden">
    <hub-banner />
    <article-list />
  </div>
</template>

<script>
import ArticleList from "@/components/hub/HubList.vue";
import HubBanner from "@/components/hub/HubBanner.vue";

export default {
  components: { ArticleList, HubBanner },
  methods: {
    track() {
      this.$gtag.pageview({
        page_path: "/transformer-hub",
      });
    },
  },
};
</script>