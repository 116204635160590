<template>
  <div class="fancy-hero-five">
    <div class="bg-wrapper">
      <div class="container">
        <div class="text-center">
          <h3 class="page-title mb-10">You Had One Job! Read the T&Cs</h3>
          <h1 class="heading">It's about to get legally binding</h1>
        </div>
      </div>
    </div> <!-- /.bg-wrapper -->
  </div>
</template>


<style scoped>
/*-------------------- Fancy Hero Five ---------------------*/
.fancy-hero-five {
  background: linear-gradient(45deg, #1cdeff 30%, #3267fc);;
  padding: 100px 0 80px;
  position: relative;
  z-index: 1;
}
/* .fancy-hero-five {
  background: #f7faff; 
  background: linear-gradient(180deg, #f1f6fd, #f7faff); 
  background: linear-gradient(180deg, #3267fc 30%, #1cdeff); 
  padding: 80px 0 80px;
  position: relative;
  z-index: 5;
} */
.fancy-hero-five .page-title {
  font-size: 20px;
  color: #fff;
  /* letter-spacing: 3px; */
  text-transform: uppercase;
  padding-bottom: 15px;
}

.fancy-hero-five .page-info {
  font-size: 15px;
  color: #fff;
  letter-spacing: 3px;
  text-transform: uppercase;
  padding-bottom: 15px;
}
.fancy-hero-five .heading {
  font-family: "gilroy-black", sans-serif, serif;
  font-size: 65px;
  line-height: 1.08em;
  line-height: 1.1em;
  color: #000;
}
.fancy-hero-five span {
  position: relative;
  z-index: 1;
}
.fancy-hero-five span img {
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}
.fancy-hero-five .sub-heading {
  font-size: 24px;
  line-height: 1.58em;
  color: #2a2a2a;
  padding-top: 50px;
  padding-right: 50px;
}
.fancy-hero-five .sub-heading.space-xs {
  padding: 15px 0 0;
}
.fancy-hero-five .img-meta {
  bottom: -250px;
  right: 33px;
  position: absolute;
}
.fancy-hero-five .shape-one {
  right: 0;
  top: 24%;
}
.fancy-hero-five .shape-two {
  left: 0;
  top: 28%;
}
</style>