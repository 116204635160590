<template>
  <div class="terms_and_policy">
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a class="nav-link active" data-bs-toggle="tab" href="#opt1"
                >Terms and Conditions</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" data-bs-toggle="tab" href="#opt2"
                >Privacy Policy</a
              >
            </li>
          </ul>
        </div>

        <div class="col-lg-9">
          <!-- Tab panes -->
          <div class="tab-content">
            <div
              v-for="(item, index) in legalese_data"
              :key="index"
              :id="item.id"
              :class="`tab-pane fade ${item.active ? 'active show' : ''}`"
            >
              <h2 class="font-gilroy-bold">{{ item.title }}</h2>
              <div class="update-date">{{ item.maintenance }}</div>
              <span
                v-for="(point, index2) in item.points"
                :key="index2"
                :id="index2"
              >
                <h3>{{ index2 + 1 }}. {{ point.title }}</h3>
                <p v-html="point.text"></p>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      legalese_data: [
        {
          id: "opt1",
          active: true,
          title: "Terms and Conditions",
          maintenance: "Last updated: 29th Jan 2023",
          points: [
            {
              title: "Introduction",
              text: 'These terms and conditions (“Terms”) govern your use of this website (“Site”) owned and operated by Overmind Digital, Inc. ("Company"). By using this Site, you agree to be bound by these Terms.',
            },
            {
              title: "Use of Site",
              text: "The Site and its content are for informational purposes only and may not be used for commercial purposes without prior written consent from the Company.",
            },
            {
              title: "Proprietary Rights",
              text: "All content on the Site, including text, graphics, logos, images, and software, is the property of the site owner or its licensors and is protected by copyright and other intellectual property laws",
            },
            {
              title: "User Content",
              text: "The Site may allow users to post content, such as comments or reviews. The site owner is not responsible for user-generated content and may remove any such content at its discretion",
            },
            {
              title: "Disclaimer of Warranties",
              text: "The Site and its content are provided on an “as is” basis without any warranties of any kind, either express or implied. The Company disclaims all warranties, including but not limited to warranties of merchantability and fitness for a particular purpose.",
            },
            {
              title: "Limitation of Liability",
              text: "The site owner shall not be liable for any damages arising from the use of the Site or its content, including but not limited to indirect, incidental, punitive, and consequential damages.",
            },
            {
              title: "Indemnification",
              text: "You agree to indemnify and hold the Company harmless from any claims, damages, or expenses arising from your use of the Site.",
            },
            {
              title: "Governing Law",
              text: "These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which the Site is located",
            },
            {
              title: "Changes to Terms",
              text: "The Company may modify these Terms at any time without prior notice to you. Your continued use of the Site following any changes indicates your acceptance of the new Terms.",
            },
            {
              title: "Contact Information",
              text: "If you have any questions regarding these Terms, please contact the company <a href='/contact-us'><strong>Contact us</strong></a>",
            },
          ],
        },
        {
          id: "opt2",
          active: false,
          title: "Privacy Policy",
          maintenance: "Last updated: 29th Jan 2023",
          points: [
            {
              title: "Introduction",
              text: 'This Privacy Policy explains how the website (“Site”) collects, uses, and discloses personal information from its users. By using the Site, you consent to the collection, use, and disclosure of your personal information in accordance with this Privacy Policy.',
            },
            {
              title: "Information Collection",
              text: "The Site may collect personal information such as your name, email address, and other contact information when you sign up for an account or interact with the Site in other ways. The Site may also collect non-personal information, such as your IP address, browser type, and operating system.",
            },
            {
              title: "Use of Information",
              text: "The Site may use the personal information collected to provide services, respond to your inquiries, and improve the Site. The Site may also use non-personal information for analytics and to improve the Site’s functionality.",
            },
            {
              title: "Disclosure of Information",
              text: "The Site will not disclose your personal information to third parties unless required by law or with your consent. The Site may share non-personal information with third parties for analytics and other purposes.",
            },
            {
              title: "Disclaimer of Warranties",
              text: "The Site and its content are provided on an “as is” basis without any warranties of any kind, either express or implied. The Company disclaims all warranties, including but not limited to warranties of merchantability and fitness for a particular purpose.",
            },
            {
              title: "Data Security",
              text: "The Site implements reasonable security measures to protect the personal information it collects from unauthorized access, use, or disclosure. However, no method of transmitting or storing data is completely secure, and the Site cannot guarantee the security of your personal information.",
            },
            {
              title: "Cookies",
              text: "The Site may use cookies to store information about your preferences and to improve your user experience. You can choose to disable cookies in your browser settings, but doing so may limit your ability to use certain features of the Site.",
            },
            {
              title: "Changes to Privacy Policy",
              text: "The Site may update this Privacy Policy from time to time. Your continued use of the Site following any changes indicates your acceptance of the new Privacy Policy.",
            },
            {
              title: "Contact Information",
              text: "If you have any questions or concerns about this Privacy Policy or the Site’s collection, use, and disclosure of personal information, please contact the Site. <a href='/contact-us'><strong>Contact us</strong></a>",
            },
          ],
        },        
      ],
    };
  },
};
</script>

<style scoped>
/*----------------- Terms and Condition ---------------*/
.terms_and_policy {
  padding: 100px 0;
  
  /* border-bottom: 1px solid #ececec; */
}
 .terms_and_policy .nav-tabs {
   border: none;
  /* background: #2884da;
  padding: 30px 10px 30px 45px;  */
} 
.terms_and_policy .nav-item {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  margin: 0;
}
.terms_and_policy .nav-item .nav-link {
  font-family: "Rubik", sans-serif;
  font-size: 22px;
  line-height: 40px;
  padding: 0;
  background: transparent;
  border: none;
  color: black;
  transition: all 1s ease-in-out;
}
.terms_and_policy .nav-item .nav-link.active {
  color: #6102ea;
}
.terms_and_policy .tab-content {
  padding: 0 0 0 20px;
}
.terms_and_policy .tab-content h2 {
  font-size: 45px;
  padding-bottom: 10px;
}
.terms_and_policy .tab-content .update-date {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
}
.terms_and_policy .tab-content h3 {
  font-family: "gilroy-semibold";
  font-size: 22px;
  padding: 50px 0 25px;
}
.terms_and_policy .tab-content p {
  line-height: 30px;
  margin-bottom: 15px;
}
.terms_and_policy .tab-content p a {
  text-decoration: underline;
  color: #000;
}
.terms_and_policy .tab-content ul {
  list-style-type: disc;
  padding-left: 18px;
}
.terms_and_policy .tab-content ul li {
  color: #000;
}
.terms_and_policy .tab-content ul li a {
  text-decoration: underline;
}
.terms_and_policy .tab-content ul li {
  padding-bottom: 10px;
}
</style>