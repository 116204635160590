<template>
  <div class="main-page-wrapper font-gordita overflow-hidden">
    <legal-banner />
    <legal-terms />
  </div>
</template>

<script>
import LegalTerms from "@/components/legal/LegalTerms.vue";
import LegalBanner from "@/components/legal/LegalBanner.vue";

export default {
  components: {
    LegalBanner,
    LegalTerms,
  },
  methods: {
    track() {
      this.$gtag.pageview({
        page_path: "/terms-and-conditions",
      });
    },
  },
};
</script>