<template>
  <the-header :doc_signature="true" />
  <router-view v-slot="{ Component }">
    <transition name="route" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
  <the-footer :dark_btn="true" />
</template>

<script>
import TheHeader from "@/components/common/TheHeader.vue";
import TheFooter from "@/components/common/TheFooter.vue";
import AOS from "aos";

export default {
  components: {
    TheHeader,
    TheFooter,
  },
  mounted() {
    AOS.init({
      duration: 1200,
    });
  },
}

</script>

<style>
.route-enter-from {
  opacity: 0;
  transform: translateY(-50px);
}

.route-leave-to {
  opacity: 0;
  transform: translateY(50px);
}

.route-enter-active {
  transition: all 0.5s ease-out;
}

.route-leave-active {
  transition: all 0.5s ease-in;
}

.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>

