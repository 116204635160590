<template>
  <div class="main-page-wrapper font-gordita overflow-hidden">
    <article-banner :title="item.title" :topic="item.tag" :reading_time="item.readingTime"
      :publish_time="item.publishedOn" />
    <article-body :detail="{
      title: item.title,
      artId: item.id,
      headerImage: item.headerImage,
      preintro: item.preIntroduction,
      intro: item.introduction,
      driver: item.drivers,
      precon: item.preConclusion,
      conc: item.conclusion,
      steps: item.steps,
      files: item.documents,
    }" :doc_banner="true" />
  </div>
</template>

<script>
import axios from "axios";
import ArticleBanner from "@/components/article/ArticleBanner.vue";
import ArticleBody from "@/components/article/ArticleBody.vue";

export default {
  components: {  ArticleBanner,  ArticleBody },
  data() {
    return {
      itemId: this.$route.params.id,
      item: {},
    };
  },
  methods: {
    async getArticle(artId) {
      await axios
        .get("https://api.overmind.digital/api/Article", {
          params: {
            id: artId,
          },
        })
        .then((response) => {
          this.item = response.data[0];
          document.title = this.item.tag + ': ' + this.item.title + ' | Overmind Digital';
          document.querySelector("meta[name='description']").setAttribute('content', this.item.preIntroduction.slice(0, 160));
          document.querySelector("meta[name='keywords']").setAttribute('content', this.item.preIntroduction.slice(0, 60)); 
        })
        .catch(() => {

        })
        .finally(() => {
          // always executed
        });
    },
  },
  created() {
    console.log('this.$route.params.id: ' + this.$route.params.id);
    this.getArticle(this.itemId);
  }
};
</script>