<template>
  <div class="main-page-wrapper font-gordita overflow-hidden">
    <main-banner />
    <product-offering />
    <product-brief />
    <frequent-questions />
  </div>
</template>

<script>
import MainBanner from "@/components/home/MainBanner.vue";
import ProductOffering from "@/components/home/ProductOffering.vue";
import ProductBrief from "@/components/home/ProductBrief.vue";
import FrequentQuestions from "@/components/home/FrequentQuestions.vue";
// import ArticleSpotlight from '@/components/article/ArticleSpolight.vue'

export default {
  components: {
    MainBanner,
    ProductOffering,
    ProductBrief,
    // ArticleSpotlight,
    FrequentQuestions,
  },
  methods: {
    track() {
      this.$gtag.pageview({
        page_path: "/",
      });
    },
  }
};
</script>
