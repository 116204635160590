<template>
  <div
    class="modal fade modal-contact-popup-one"
    id="contactModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="contactModalTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <main class="main-body modal-content clearfix">
        <button
          type="button"
          class="close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <img src="@/assets/images/icon/close.svg" alt="" />
        </button>
        <div class="left-side">
          <div class="d-flex flex-column justify-content-between h-100">
            <div class="row">
              <div class="col-xl-10 col-lg-8 m-auto">
                <blockquote>
                  “Coming together is a beginning, 
                  staying together is progress,
                  and working together is success”
                </blockquote>
                <span class="bio">—Henry Ford</span>
              </div>
            </div>
            <!-- <img src="../../assets/images/assets/ils_18.svg" alt="" class="illustration mt-auto"> -->
            <img
              src="@/assets/images/assets/overmind_consults.png"
              alt="Overmind provides consulting services at your pace and convenience"
              class="illustration mt-auto"
            />
          </div>
        </div>
        <!-- /.left-side -->
        <div class="right-side">
          <h2 class="form-title">At your reach..</h2>
          <form
            @submit.prevent="onSubmit"
            id="contact-form"
            data-bs-toggle="validator"
          >
            <div class="messages"></div>
            <div class="row controls">
              <div class="col-12">
                <div class="input-group-meta form-group mb-15">
                  <input
                    type="text"
                    placeholder="Your Name*"
                    name="fullname"
                    required="required"
                    data-error="Name is required."
                    v-model="fullName"
                  />
                  <div class="help-block with-errors"></div>
                </div>
              </div>
              <div class="col-12">
                <div class="input-group-meta form-group mb-15">
                  <input
                    type="email"
                    placeholder="Email Address*"
                    name="email"
                    required="required"
                    data-error="Valid email is required."
                    v-model="email"
                  />
                  <div class="help-block with-errors"></div>
                </div>
              </div>
              <div class="col-12">
                <div class="input-group-meta form-group mb-15">
                  <input
                    type="text"
                    placeholder="Your subject*"
                    name="subject"
                    required="required"
                    data-error="Meaningful subject is requried."
                    v-model="subject"
                  />
                  <div class="help-block with-errors"></div>
                </div>
              </div>
              <div class="col-12">
                <div class="input-group-meta form-group mb-15">
                  <textarea
                    rows="5"
                    placeholder="Your message*"
                    name="message"
                    required="required"
                    data-error="Please,leave us a message."
                    v-model="message"
                  ></textarea>
                  <div class="help-block with-errors"></div>
                </div>
              </div>
              <div class="col-12">
                <button class="theme-btn-seven w-100">Send Message</button>
              </div>
              <div v-cloak class="col-12 result-message" :class="{'warning': hasError}">
                {{ result}}

              </div>              
            </div>
          </form>
        </div>
        <!-- /.right-side -->
      </main>
      <!-- /.main-body -->
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      fullName: "",
      email: "",
      subject: "",
      message: "",
      result: null,
      hasError: false,
    };
  },
  methods: {
    async onSubmit() {
      await axios
        .post("https://api.overmind.digital/api/Lead/Advise", {
          name: this.fullName,
          email: this.email,
          subject: this.subject,
          message: this.message,
        })
        .then(() => {
          this.fullName = "";
          this.subject = "";
          this.email = "";
          this.message = "";
          this.result = "Messages sent successfully, we will in touch soon.";
        })
        .catch(() => {
          this.result = "Oops. somthing went wrong, please try again later";
          this.hasError = true;
        });
    },
  },
};
</script>

<style scoped>
.result-message {
  color: #6102ea;
  margin-top: 10px;
  font-weight: 500;
}
.warning {
  color: red;
}
</style>