<template>
  <div class="fancy-hero-one">
    <div class="container">
      <div class="row">
        <div class="col-xl-9 col-lg-11 m-auto">
          <h2 class="font-rubik">Feel free to contact us or just say hi!</h2>
        </div>
      </div>
    </div>
    <div class="bubble-one"></div>
    <div class="bubble-two"></div>
    <div class="bubble-three"></div>
    <div class="bubble-four"></div>
    <div class="bubble-five"></div>
    <div class="bubble-six"></div>
  </div>
</template>
