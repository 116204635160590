<template>
  <div class="hero-banner-eleven lg-container">
    <div class="container">
      <div class="row">
        <div class="col-xl-7 col-lg-6">
          <div class="hero-text-wrapper">
            <h1>
              Your digital<br />
              <span>
                Transformation
                <img
                  src="@/assets/images/shape/line-shape-12.svg"
                  alt=""
                  class="cs-screen"
                />
              </span>
              assistant
            </h1>
            <p class="hero-sub-heading">
              Transforming your business, one click at a time
            </p>
            <form @submit.prevent="onSubmit">
              <input
                type="email"
                placeholder="your-email@address.com"
                v-model="email"
                required="required"
              />
              <button>Keep me posted</button>
            </form>
            <p
              v-cloak
              class="result-message term-text"
              :class="{ warning: hasError }"
            >
              {{ result }}
            </p>
          </div>
          <!-- /.hero-text-wrapper -->
        </div>
        <!-- End .col -->

        <div class="illustration-container">
          <img src="@/assets/images/banner/main-banner.png" alt="" />
        </div>
      </div>
      <!-- End .row -->
    </div>
    <!-- End .container -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      hasError: false,
      email: "",
      result: null,
    };
  },
  methods: {
    async onSubmit() {
      await axios
        .post("https://api.overmind.digital/api/Lead/Waitlist", {
          email: this.email,
        })
        .then(() => {
          this.email = "";
          this.result = "Thank you, we will. Stay tuned..";
        })
        .catch(() => {
          this.result = "Oops. somthing went wrong, please try again later";
          this.hasError = true;
        });
    },
  },
};
</script>

<style scoped>
.result-message {
  color: #6102ea;
  font-weight: 500;
}
.warning {
  color: red;
}
</style>
